import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { BACKEND_URL } from '../constants';
import {
  Metadata,
  ProcessMeetingJobApi,
  RecordingDTO,
  RecordingsList,
  RenameRecording,
} from '@axys-notes/common';
import { useTeamsContext } from '../providers/teams-context-provider';
import { handleErrorResponse } from './common';

export const useRecordMeetingMutation = () => {
  const { authCode, renewAuthCode } = useTeamsContext();
  return useMutation<void, Record<string, unknown>, ProcessMeetingJobApi>({
    mutationKey: ['search'],
    mutationFn: async (query: ProcessMeetingJobApi) => {
      const response = await fetch(`${BACKEND_URL}/api/recording`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authCode ? `Bearer ${authCode}` : '',
        },
        body: JSON.stringify(query),
      });

      await handleErrorResponse(response, renewAuthCode);
      return;
    },
  });
};

export const useSearchRecordingsQuery = (searchQuery: string, page: number) => {
  const { authCode, renewAuthCode } = useTeamsContext();
  return useQuery<RecordingsList>({
    enabled: !!authCode,
    queryKey: ['recording-list-get', searchQuery, page],
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchInterval: 10000,
    queryFn: async () => {
      const response = await fetch(
        `${BACKEND_URL}/api/recording?${new URLSearchParams({
          searchQuery,
          page: String(page),
        })}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authCode ? `Bearer ${authCode}` : '',
          },
        }
      );
      await handleErrorResponse(response, renewAuthCode);
      return response.json();
    },
  });
};

export const useGetMetadataQuery = () => {
  const { authCode, renewAuthCode } = useTeamsContext();
  return useQuery<Metadata>({
    enabled: !!authCode,
    queryKey: ['metadata'],
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchInterval: 10000,
    queryFn: async () => {
      const response = await fetch(`${BACKEND_URL}/api/recording/metadata`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authCode ? `Bearer ${authCode}` : '',
        },
      });
      await handleErrorResponse(response, renewAuthCode);
      return response.json();
    },
  });
};

export const useGetRecordingQuery = (recordingId?: string) => {
  const { authCode, renewAuthCode } = useTeamsContext();
  return useQuery<RecordingDTO>({
    enabled: !!authCode && !!recordingId,
    queryKey: ['recording-get', recordingId],
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchInterval: 10000,
    queryFn: async () => {
      const response = await fetch(
        `${BACKEND_URL}/api/recording/${recordingId}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authCode ? `Bearer ${authCode}` : '',
          },
        }
      );
      await handleErrorResponse(response, renewAuthCode);
      return response.json();
    },
  });
};

export const useExportReportMutation = () => {
  const { authCode, renewAuthCode } = useTeamsContext();
  return useMutation<Blob, Record<string, unknown>, string>({
    mutationKey: ['search'],
    mutationFn: async (id: string) => {
      const response = await fetch(
        `${BACKEND_URL}/api/recording/${id}/report`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authCode ? `Bearer ${authCode}` : '',
          },
        }
      );

      await handleErrorResponse(response, renewAuthCode);
      return response.blob();
    },
  });
};

export const useRenameMeetingMutation = () => {
  const { authCode, renewAuthCode } = useTeamsContext();
  const queryClient = useQueryClient();
  return useMutation<
    void,
    Record<string, unknown>,
    RenameRecording & { recordingId: string }
  >({
    mutationKey: ['search'],
    mutationFn: async (query) => {
      const { recordingId, ...dto } = query;
      const response = await fetch(
        `${BACKEND_URL}/api/recording/${recordingId}/name`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authCode ? `Bearer ${authCode}` : '',
          },
          body: JSON.stringify(dto),
        }
      );

      await handleErrorResponse(response, renewAuthCode);
      return;
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['recording-list-get'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['recording-get', variables.recordingId],
      });
    },
  });
};
