import {
  Button,
  Card,
  Input,
  makeStyles,
  mergeClasses,
  Spinner,
  tokens,
} from '@fluentui/react-components';
import { ChatAddRegular, SendRegular } from '@fluentui/react-icons';
import { ChatSelectionMenu } from './ChatSelectionMenu';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  useCreateChatMutation,
  useDeleteChatMutation,
  useGetChatQuery,
  useListChatsQuery,
  useSendMessageMutation,
} from '../../queries/chat';
import { ChatPanel, ChatPanelMessage, LoadingMessage } from './ChatPanel';
import { ChatHistoryMessage } from '@axys-notes/common';
import { useTeamsContext } from '../../providers/teams-context-provider';

const useStyles = makeStyles({
  cardStyle: {
    backgroundColor: tokens.colorSubtleBackgroundHover,
    display: 'grid',
    gridTemplateAreas: `
          'menu  chat'
         `,
    gridTemplateColumns: '300px 1fr',
    gap: '20px',
    minHeight: '400px',
  },
  menuStyle: {
    display: 'grid',
    backgroundColor: tokens.colorNeutralBackground1,
    boxShadow: `${tokens.shadow16}`,
    padding: '10px',
    gridArea: 'menu',
    height: '0px',
    minHeight: '97%',
    maxHeight: '97%',
    gridTemplateAreas: `
        'list'
        'new'
    `,
    gridTemplateRows: '1fr 50px',
  },
  chatStyle: {
    display: 'grid',
    backgroundColor: tokens.colorNeutralBackground1,
    boxShadow: `${tokens.shadow16}`,
    padding: '10px',
    gridArea: 'chat',
    minHeight: '97%',
    maxHeight: '97%',
    gridTemplateAreas: `
        'messages'
        'input'
    `,
    gridTemplateRows: '1fr 50px',
  },
  messagesStyle: {
    gridArea: 'messages',
    height: '0px',
    minHeight: '98%',
    marginLeft: '0px',
    marginRight: '0px',
    maxWidth: '98%',
    minWidth: '98%',
  },
  inputStyle: {
    gridArea: 'input',
  },
  chatSelectionMenuStyle: {
    gridArea: 'list',
  },
  newChatButtonStyle: {
    gridArea: 'new',
  },
});

export const Chats = (props: { className?: string | undefined }) => {
  const { className } = props;
  const styles = useStyles();

  const [selectedDiscussionId, setSelectedDiscussionId] = useState<
    string | undefined
  >('');

  const [message, setMessage] = useState<string>('');
  const [currentMessages, setCurrentMessages] = useState<ChatPanelMessage[]>(
    []
  );

  const { data: discussions, isLoading: isListLoading } = useListChatsQuery();

  const { mutateAsync: createChat, isPending: isCreatingPending } =
    useCreateChatMutation();

  const { mutate: deleteChat, isPending: isDeletionPending } =
    useDeleteChatMutation();

  const {
    mutateAsync: sendUserMessage,
    isPending: isReceivingResponse,
    cancel: cancelSendUserMessange,
  } = useSendMessageMutation();

  const { data: chatHistory } = useGetChatQuery(selectedDiscussionId);

  const inputRef = useRef<HTMLInputElement>(null);

  const focusInput = useCallback(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 500);
  }, []);

  const onSendMessage = useCallback(async () => {
    const userMessage: ChatPanelMessage = {
      user: true,
      message,
    };

    let botResponse: ChatPanelMessage = {
      user: false,
      loading: true,
    };

    if (selectedDiscussionId && message && !isReceivingResponse) {
      setCurrentMessages([...currentMessages, userMessage, botResponse]);
      setMessage('');
      await sendUserMessage(
        selectedDiscussionId,
        {
          message,
        },
        (chunk) => {
          if ((botResponse as LoadingMessage).loading) {
            botResponse = {
              user: false,
              message: chunk.text,
            };
          } else {
            (botResponse as ChatHistoryMessage).message += chunk.text;
          }
          setCurrentMessages([...currentMessages, userMessage, botResponse]);
        },
        () => {
          focusInput();
        }
      );
    }
  }, [
    focusInput,
    isReceivingResponse,
    currentMessages,
    sendUserMessage,
    selectedDiscussionId,
    message,
  ]);

  const changeDiscussion = useCallback(
    (discussionId: string) => {
      if (discussionId !== selectedDiscussionId) {
        cancelSendUserMessange();
        setSelectedDiscussionId(discussionId);
        setCurrentMessages([]);
        focusInput();
      }
    },
    [selectedDiscussionId, focusInput, cancelSendUserMessange]
  );

  const createAndSelectChat = useCallback(
    async (recordingId?: string) => {
      const result = await createChat({
        recordingId,
      });
      setTimeout(() => {
        changeDiscussion(result.discussionId);
      }, 50);
    },
    [createChat, changeDiscussion]
  );

  const { teamsContext } = useTeamsContext();

  useEffect(() => {
    if (teamsContext?.page.subPageId) {
      setSelectedDiscussionId(teamsContext?.page.subPageId);
      focusInput();
    }
  }, [focusInput, teamsContext?.page.subPageId]);

  return (
    <Card
      className={
        className ? mergeClasses(styles.cardStyle, className) : styles.cardStyle
      }
    >
      <div className={styles.menuStyle}>
        {isListLoading || isCreatingPending || isDeletionPending ? (
          <Spinner />
        ) : (
          <>
            <ChatSelectionMenu
              className={styles.chatSelectionMenuStyle}
              chats={discussions || []}
              onSelectDiscussion={changeDiscussion}
              selectedDiscussionId={selectedDiscussionId}
              onDeleteDiscussion={(discussionId) => deleteChat(discussionId)}
            />
            <Button
              className={styles.newChatButtonStyle}
              appearance="secondary"
              size="large"
              aria-label="new discussion"
              icon={<ChatAddRegular />}
              onClick={() => createAndSelectChat()}
            >
              Nouvelle discussion
            </Button>
          </>
        )}
      </div>
      <form
        className={styles.chatStyle}
        onSubmit={(e) => {
          e.preventDefault();
          void onSendMessage();
        }}
      >
        {!!selectedDiscussionId && (
          <>
            <ChatPanel
              className={styles.messagesStyle}
              discussionId={selectedDiscussionId}
              chatHistory={chatHistory}
              pendingHistory={currentMessages}
            />
            <Input
              ref={inputRef}
              className={styles.inputStyle}
              disabled={isReceivingResponse}
              contentAfter={
                <Button
                  disabled={!message || isReceivingResponse}
                  appearance="transparent"
                  type="submit"
                  icon={<SendRegular aria-label="Envoyer" />}
                  size="small"
                />
              }
              id="send"
              value={message}
              onChange={(_, data) => setMessage(data.value)}
            />
          </>
        )}
      </form>
    </Card>
  );
};
