import { z } from 'zod';
import { ChatDescriptionSchema } from './chat-description';

export const ChatHistoryMessageSchema = z.object({
  user: z.boolean(),
  message: z.string(),
});

export type ChatHistoryMessage = z.infer<typeof ChatHistoryMessageSchema>;

export const ChatHistorySchema = ChatDescriptionSchema.extend({
  messages: z.array(ChatHistoryMessageSchema),
});

export type ChatHistory = z.infer<typeof ChatHistorySchema>;
