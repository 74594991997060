import {
  Button,
  Card,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components';
import React, { RefObject, useCallback } from 'react';
import {
  ClipboardArrowRightRegular,
  DocumentTextRegular,
  PersonChatRegular,
} from '@fluentui/react-icons';
import { useExportReportMutation } from '../../queries/recording';
import { pages } from '@microsoft/teams-js';
import { TEAMS_APP_ID } from '../../constants';
import { useCreateChatMutation } from '../../queries/chat';

const useStyles = makeStyles({
  cardStyle: {
    backgroundColor: tokens.colorSubtleBackgroundHover,
    flex: 1,
  },
  wrapperStyle: {
    display: 'flex',
    flex: 1,
    gap: '10px',
  },
});

export const RecordingActionsBar = (props: {
  className?: string;
  recordingId: string;
  meetingNotesref: RefObject<HTMLDivElement>;
  globalInfosRef: RefObject<HTMLDivElement>;
}) => {
  const { className, recordingId, meetingNotesref, globalInfosRef } = props;
  const { mutateAsync: doExport, isPending: isExporting } =
    useExportReportMutation();

  const exportReport = useCallback(async () => {
    const file = await doExport(recordingId);
    const url = window.URL.createObjectURL(file);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${recordingId}.docx`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }, [doExport, recordingId]);

  const copyToClipboard = useCallback(async () => {
    if (globalInfosRef?.current && meetingNotesref?.current) {
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/plain': new Blob(
            [
              globalInfosRef?.current.innerText,
              meetingNotesref?.current.innerText,
            ],
            {
              type: 'text/plain',
            }
          ),
          'text/html': new Blob(
            [
              globalInfosRef?.current.innerHTML,
              meetingNotesref?.current.innerHTML,
            ],
            {
              type: 'text/html',
            }
          ),
        }),
      ]);
    }
  }, [globalInfosRef, meetingNotesref]);

  const { mutateAsync: createChat, isPending: isCreatingPending } =
    useCreateChatMutation();

  const createDiscussion = useCallback(async () => {
    const chat = await createChat({
      recordingId,
    });

    const pageId = 'axys.notes.chats';
    const subPageId = chat.discussionId;
    if (pages.currentApp.isSupported()) {
      await pages.currentApp.navigateTo({
        pageId,
        subPageId,
      });
    } else {
      await pages.navigateToApp({
        appId: TEAMS_APP_ID!,
        pageId,
        subPageId,
      });
    }
  }, [createChat, recordingId]);

  const styles = useStyles();

  return (
    <Card
      className={
        className ? mergeClasses(styles.cardStyle, className) : styles.cardStyle
      }
    >
      <div className={styles.wrapperStyle}>
        <Button
          appearance="primary"
          aria-label="export"
          icon={<PersonChatRegular />}
          onClick={createDiscussion}
          disabled={isCreatingPending}
        >
          Assistant
        </Button>
        <Button
          appearance="secondary"
          aria-label="export"
          icon={<DocumentTextRegular />}
          onClick={exportReport}
          disabled={isExporting}
        >
          Exporter
        </Button>
        <Button
          appearance="secondary"
          aria-label="export"
          icon={<ClipboardArrowRightRegular />}
          onClick={copyToClipboard}
        >
          Copier dans le presse-papier
        </Button>
      </div>
    </Card>
  );
};
