import {
  Button,
  makeStyles,
  mergeClasses,
  Tag,
  tokens,
} from '@fluentui/react-components';
import { List, ListItem } from '@fluentui/react-components';
import React, { useCallback, useState } from 'react';
import { CalendarLtrRegular, DeleteRegular } from '@fluentui/react-icons';
import { ChatDescription } from '@axys-notes/common';

const useStyles = makeStyles({
  wrapperStyle: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  listStyle: {
    flex: 1,
  },
  itemStyle: {
    cursor: 'pointer',
    padding: '5px 6px',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${tokens.colorSubtleBackgroundSelected}`,
  },
  itemSelectedStyle: {
    backgroundColor: tokens.colorSubtleBackgroundSelected,
    '@media (forced-colors:active)': {
      background: 'Highlight',
    },
  },
  buttonWrapperStyle: {
    alignSelf: 'center',
  },
  listItemNameAndBadgeWrapperStyle: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: 0,
    padding: '5px',
  },
  nameWrapperStyle: {
    flex: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '5px',
  },
  scopeBadgeStyle: {},
  innerBadgeStyle: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

export const ChatSelectionMenu = (props: {
  className?: string;
  selectedDiscussionId?: string;
  onSelectDiscussion: (discussionId: string) => void;
  onDeleteDiscussion: (discussionId: string) => void;
  chats: ChatDescription[];
}) => {
  const { className, selectedDiscussionId, onSelectDiscussion, chats, onDeleteDiscussion } =
    props;
  const styles = useStyles();
  const [hover, setHover] = useState<string | undefined>(undefined);
  const [width, setWidth] = useState<number | undefined>(undefined);
  const div = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  return (
    <div className={mergeClasses(styles.wrapperStyle, className)}>
      <List
        ref={div}
        className={styles.listStyle}
        selectionMode="single"
        navigationMode="composite"
        selectedItems={selectedDiscussionId ? [selectedDiscussionId] : []}
        onSelectionChange={(_, data) => {
          onSelectDiscussion(data.selectedItems[0]?.toString());
        }}
      >
        {chats.map(({ name, timestamp, recording, discussionId }) => (
          <ListItem
            key={discussionId}
            value={discussionId}
            className={mergeClasses(
              styles.itemStyle,
              selectedDiscussionId === discussionId && styles.itemSelectedStyle
            )}
            onMouseEnter={() => setHover(discussionId)}
            onMouseLeave={() => setHover(undefined)}
            onFocus={(event) => {
              if (event.target !== event.currentTarget) {
                return;
              }
              if (event.target.dataset.value) {
                onSelectDiscussion(event.target.dataset.value);
              }
            }}
            checkmark={null}
          >
            <div className={styles.listItemNameAndBadgeWrapperStyle}>
              <div className={styles.nameWrapperStyle}>
                {name || new Date(timestamp).toLocaleDateString()}
              </div>
              {!!recording && (
                <Tag
                  appearance="brand"
                  className={styles.scopeBadgeStyle}
                  icon={<CalendarLtrRegular />}
                >
                  <span
                    style={{ maxWidth: width ? `${width - 100}px` : 0 }}
                    className={styles.innerBadgeStyle}
                  >
                    {recording.meetingName || "..."}
                  </span>
                </Tag>
              )}
            </div>
            {hover === discussionId && (
              <div role="gridcell" className={styles.buttonWrapperStyle}>
                <Button
                  size="small"
                  icon={<DeleteRegular />}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteDiscussion(discussionId);
                  }}
                />
              </div>
            )}
          </ListItem>
        ))}
      </List>
    </div>
  );
};
