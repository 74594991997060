import React, { useState } from 'react';
import { NewRecordingPanel } from '../../components/new-recording-panel';
import { RecordingsListPanel } from '../../components/recordings-list-panel';
import { StatsPanel } from '../../components/stats-panel';
import { useGetMetadataQuery } from '../../queries/recording';
import { useIsLargeScreen } from '../../utils/use-is-large-screen';
import { RecordingDrawer } from '../../components/recording-drawer';
import { makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({
  containerStyle: {
    flex: 1,
    gap: '20px',
    padding: '20px',
    display: 'grid',
  },
  newRecordingPanelStyle: { gridArea: 'new' },
  statsPanelStyle: { gridArea: 'stats' },
  recordingsListStyle: { gridArea: 'table' },
});

export const HomePage = () => {
  const { data } = useGetMetadataQuery();

  const [displayedRecordingId, setDisplayedRecordingId] = useState<
    string | undefined
  >(undefined);

  const isLargeScreen = useIsLargeScreen();

  const styles = useStyles();

  return (
    <>
      <div
        className={styles.containerStyle}
        style={{
          gridTemplateAreas: isLargeScreen
            ? `
          'stats stats .     new   new   new'
          'table table table table table table'
         `
            : `
          'stats'
          'new'
          'table'
        `,
          gridTemplateColumns: isLargeScreen ? '1fr 1fr 1fr 1fr 1fr 1fr' : '1fr',
          gridTemplateRows: isLargeScreen ? '200px 1fr' : '200px 200px 1fr',
        }}
      >
        <StatsPanel className={styles.statsPanelStyle} />
        <NewRecordingPanel
          className={styles.newRecordingPanelStyle}
          disabled={data?.user.hasRunningRecording}
        />
        <RecordingsListPanel
          className={styles.recordingsListStyle}
          openMeetingReport={setDisplayedRecordingId}
        />
      </div>
      {displayedRecordingId && (
        <RecordingDrawer
          recordingId={displayedRecordingId}
          onClose={() => setDisplayedRecordingId(undefined)}
        />
      )}
    </>
  );
};
