import { z } from 'zod';
import { stripLow } from 'validator';

export const RenameRecordingSchema = z.object({
  name: z.string().transform((name) => {
    return stripLow(name, false)
  }),
});

export type RenameRecording = z.infer<typeof RenameRecordingSchema>;
