import {
  Button,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  makeStyles,
  OverlayDrawer,
  Spinner,
  useRestoreFocusSource,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import React, { useRef } from 'react';
import {
  useGetRecordingQuery,
  useRenameMeetingMutation,
} from '../../queries/recording';
import { useIsLargeScreen } from '../../utils/use-is-large-screen';
import { GlobalInformations } from '../recording-global-informations';
import { MeetingNotes } from '../meeting-notes';
import { RecordingActionsBar } from '../recording-actions-bar';

const useStyle = makeStyles({
  wrapperStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '20px 0',
  },
});

export const RecordingDrawer = (props: {
  recordingId?: string;
  onClose: () => void;
}) => {
  const { recordingId, onClose } = props;
  const { data, isLoading } = useGetRecordingQuery(recordingId);
  const { mutate } = useRenameMeetingMutation();

  const restoreFocusSourceAttributes = useRestoreFocusSource();

  const isLargeScreen = useIsLargeScreen();

  const meetingNotesref = useRef<HTMLDivElement>(null);
  const globalInfosRef = useRef<HTMLDivElement>(null);

  const styles = useStyle();

  return (
    <OverlayDrawer
      {...restoreFocusSourceAttributes}
      size={isLargeScreen ? 'large' : 'full'}
      position="end"
      open={!!recordingId}
      onOpenChange={(_, state) => !state.open && onClose()}
    >
      <DrawerHeader>
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={onClose}
            />
          }
        >
          {isLoading || !data ? '' : data.name}
        </DrawerHeaderTitle>
      </DrawerHeader>

      <DrawerBody>
        {isLoading ? (
          <Spinner label="Loading..." size="large" />
        ) : data ? (
          <div className={styles.wrapperStyle}>
            <GlobalInformations
              data={data}
              onRename={(name) => {
                if (recordingId) {
                  mutate({
                    recordingId,
                    name,
                  });
                }
              }}
              ref={globalInfosRef}
            />
            {data.report && (
              <>
                <RecordingActionsBar
                  recordingId={data.id}
                  meetingNotesref={meetingNotesref}
                  globalInfosRef={globalInfosRef}
                />
                <MeetingNotes report={data.report} ref={meetingNotesref} />
              </>
            )}
          </div>
        ) : (
          <></>
        )}
      </DrawerBody>
    </OverlayDrawer>
  );
};
