export const handleErrorResponse = async (
  response: Response,
  renewAuthCode: (() => void) | undefined
) => {
  if (response.ok) {
    return;
  }

  let message;
  if (response.status === 403) {
    try {
      const json = await response.json();
      if (json['message']?.includes('jwt expired') && renewAuthCode) {
        renewAuthCode();
      }
      message = json.message;
    } catch (e) {
      message = response.statusText;
    }
  } else {
    message = response.statusText;
  }
  throw new Error(message);
};
