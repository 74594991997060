import { z } from 'zod';

export const ReportActionSchema = z.object({
  title: z.string(),
  context: z.string(),
  owners: z.array(z.string()),
});

export type ReportAction = z.infer<typeof ReportActionSchema>;

export const ReportDecisionSchema = z.object({
  title: z.string(),
  context: z.string(),
  owners: z.array(z.string()),
});

export type ReportDecision = z.infer<typeof ReportDecisionSchema>;

export const ReportDeadlineSchema = z.object({
  title: z.string(),
  context: z.string(),
  date: z.string(),
  owners: z.array(z.string()),
});

export type ReportDeadline = z.infer<typeof ReportDeadlineSchema>;

export const ReportKeyPointSchema = z.object({
  title: z.string(),
  context: z.string(),
})

export type ReportKeyPoint = z.infer<typeof ReportKeyPointSchema>;

export const ReportTopicSchema = z.object({
  title: z.string(),
  context: z.string(),
  keyPoints: z.array(ReportKeyPointSchema),
});

export type ReportTopic = z.infer<typeof ReportTopicSchema>;

export const MeetingReportSchema = z.object({
  subject: z.string(),
  summary: z.string(),
  topics: z.array(ReportTopicSchema),
  actions: z.array(ReportActionSchema),
  decisions: z.array(ReportDecisionSchema),
  deadlines: z.array(ReportDeadlineSchema),
});

export type MeetingReport = z.infer<typeof MeetingReportSchema>;

export const SummarizeMeetingResultSchema = z.object({
  recordingId: z.string(),
  report: MeetingReportSchema,
});

export type SummarizeMeetingResult = z.infer<
  typeof SummarizeMeetingResultSchema
>;
