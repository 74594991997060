import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Card,
  makeStyles, mergeClasses,
  tokens
} from '@fluentui/react-components';
import { List, ListItem } from '@fluentui/react-components';
import { MeetingReport } from '@axys-notes/common';
import { forwardRef } from 'react';

type Props = { className?: string, report: MeetingReport };

const useStyles = makeStyles({
  cardStyle: {
    backgroundColor: tokens.colorSubtleBackgroundHover,
    gap: 0,
  },
  h2Style: { fontWeight: 'bold', paddingLeft: "15px" },
  h5Style: { margin: "0px", fontSize: "14px" },
  firstLevelListItemStyle: {
    listStyleType: 'circle',
    marginLeft: '30px',
    marginBottom: '5px',
  },
  secondLevelListItemStyle: {
    listStyleType: 'square',
    marginLeft: '20px',
    marginBottom: '5px',
  },
  elementTitleStyle: {
    fontWeight: 'bold'
  },
  accordionPanelStyle: { margin: '0 30px' },
  accordionItemStyle: { margin: '20px 0px' }
});


export const MeetingNotes = forwardRef<HTMLDivElement, Props>(
  (props: Props, ref) => {
    const { report, className } = props;
    const styles = useStyles();
    return (
      <Card
        ref={ref}
        className={
          className ? mergeClasses(styles.cardStyle, className) : styles.cardStyle
        }
      >
        <h2 className={styles.h2Style}>
          Compte-Rendu de la réunion
        </h2>
        <div style={{ padding: '0 10px' }}>
          <Accordion
            collapsible
            multiple
            defaultOpenItems={[
              'summary',
              'topics',
              'actions',
              'decisions',
              'deadlines',
            ]}
          >
            <AccordionItem value="summary" className={styles.accordionItemStyle}>
              <AccordionHeader style={{ color: tokens.colorBrandForeground1 }}>
                <h3>Synthèse</h3>
              </AccordionHeader>
              <AccordionPanel className={styles.accordionPanelStyle}>
                {report.summary}
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="topics" className={styles.accordionItemStyle}>
              <AccordionHeader style={{ color: tokens.colorBrandForeground1 }}>
                <h3>Sujets abordés</h3>
              </AccordionHeader>
              <AccordionPanel className={styles.accordionPanelStyle}>
                <Accordion
                  collapsible
                  multiple
                  defaultOpenItems={[...report.topics.keys()].map(
                    (i) => `topic-${i}`
                  )}
                >
                  {report.topics.map((topic, indexTopic) => (
                    <AccordionItem
                      key={indexTopic}
                      value={`topic-${indexTopic}`}
                      style={{ marginBottom: 20 }}
                    >
                      <AccordionHeader
                        style={{ color: tokens.colorBrandForeground1 }}
                      >
                        {topic.title}
                      </AccordionHeader>
                      <AccordionPanel className={styles.accordionPanelStyle}>
                        <div>

                          <p>
                            {topic.context}
                          </p>

                          <Accordion
                            collapsible
                            multiple
                            defaultOpenItems={[`topic-${indexTopic}-keyPoints`]}
                          >
                            {topic.keyPoints.length > 0 && (
                              <List style={{ marginTop: 5 }}>
                                {topic.keyPoints.map(
                                  (keyPoint, indexKeypoint) => (
                                    <ListItem
                                      key={`${indexTopic}-keypoint-${indexKeypoint}`}
                                      className={styles.firstLevelListItemStyle}
                                    >
                                      <div>
                                        <span className={styles.elementTitleStyle}>{keyPoint.title} : </span>
                                        {keyPoint.context}
                                      </div>
                                    </ListItem>
                                  )
                                )}
                              </List>
                            )}
                          </Accordion>
                        </div>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="actions" className={styles.accordionItemStyle}>
              <AccordionHeader style={{ color: tokens.colorBrandForeground1 }}>
                <h3>Actions</h3>
              </AccordionHeader>
              <AccordionPanel className={styles.accordionPanelStyle}>
                <List>
                  {report.actions.map((action, actionIndex) => (
                    <ListItem
                      key={`action-${actionIndex}`}
                      className={styles.firstLevelListItemStyle}
                    >
                      <span className={styles.elementTitleStyle}>{action.title} : </span>
                      {action.context}
                      {!!action.owners?.length && (
                        <List>
                          {action.owners.map((owner, ownerIndex) => (
                            <ListItem
                              key={`action-${actionIndex}-owner-${ownerIndex}`}
                              className={styles.secondLevelListItemStyle}
                            >
                              {owner}
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </ListItem>
                  ))}
                </List>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="decisions" className={styles.accordionItemStyle}>
              <AccordionHeader style={{ color: tokens.colorBrandForeground1 }}>
                <h3>Décisions</h3>
              </AccordionHeader>
              <AccordionPanel className={styles.accordionPanelStyle}>
                <List>
                  {report.decisions.map((decision, decisionIndex) => {
                    return (
                      <ListItem
                        key={`decision-${decisionIndex}`}
                        className={styles.firstLevelListItemStyle}
                      >
                        <span className={styles.elementTitleStyle}>{decision.title} : </span>
                        {decision.context}
                        {!!decision.owners?.length && (
                          <List>
                            {decision.owners.map((owner, ownerIndex) => (
                              <ListItem
                                key={`decision-${decisionIndex}-owner-${ownerIndex}`}
                                className={styles.secondLevelListItemStyle}
                              >
                                {owner}
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="deadlines" className={styles.accordionItemStyle}>
              <AccordionHeader style={{ color: tokens.colorBrandForeground1 }}>
                <h3>Échéances</h3>
              </AccordionHeader>
              <AccordionPanel className={styles.accordionPanelStyle}>
                <List>
                  {report.deadlines.map((deadline, deadlineIndex) => {
                    return (
                      <ListItem
                        key={`deadline-${deadlineIndex}`}
                        className={styles.firstLevelListItemStyle}
                      >
                        <span className={styles.elementTitleStyle}> {deadline.title}{' '}
                          {deadline.date ? `(${deadline.date})` : ''} : </span>
                        {deadline.context}

                        {!!deadline.owners?.length && (
                          <List>
                            {deadline.owners.map((owner, ownerIndex) => (
                              <ListItem
                                key={`deadline-${deadlineIndex}-owner-${ownerIndex}`}
                                className={styles.secondLevelListItemStyle}
                              >
                                {owner}
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </Card>
    );
  }
);
