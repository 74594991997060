import { useGetMetadataQuery } from '../../queries/recording';
import {
  Card,
  makeStyles,
  mergeClasses,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  tokens,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  cardStyle: {
    backgroundColor: tokens.colorSubtleBackgroundHover,
  },
  tableNameStyle: { fontWeight: 'bold' },
  headerCellStyle: { textAlign: 'center' },
  valueCellStyle: { textAlign: 'center' },
});

export const StatsPanel = (props: { className?: string }) => {
  const { className } = props;
  const { data } = useGetMetadataQuery();
  const styles = useStyles();
  return (
    <Card
      className={
        className ? mergeClasses(styles.cardStyle, className) : styles.cardStyle
      }
    >
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell className={styles.tableNameStyle}>
              Statistiques du service
            </TableCell>
            <TableCell className={styles.headerCellStyle}>En attente</TableCell>
            <TableCell className={styles.headerCellStyle}>En cours</TableCell>
            <TableCell className={styles.headerCellStyle}>Terminé</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>Enregistrement</TableCell>
            <TableCell className={styles.valueCellStyle}>
              {data?.service?.recorder.pending ?? '...'}
            </TableCell>
            <TableCell className={styles.valueCellStyle}>
              {data?.service?.recorder.running ?? '...'}
            </TableCell>
            <TableCell className={styles.valueCellStyle}>
              {data?.service?.recorder.completed ?? '...'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Transcription</TableCell>
            <TableCell className={styles.valueCellStyle}>
              {data?.service?.stt.pending ?? '...'}
            </TableCell>
            <TableCell className={styles.valueCellStyle}>
              {data?.service?.stt.running ?? '...'}
            </TableCell>
            <TableCell className={styles.valueCellStyle}>
              {data?.service?.stt.completed ?? '...'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Compte-rendu</TableCell>
            <TableCell className={styles.valueCellStyle}>
              {data?.service?.summarizer.pending ?? '...'}
            </TableCell>
            <TableCell className={styles.valueCellStyle}>
              {data?.service?.summarizer.running ?? '...'}
            </TableCell>
            <TableCell className={styles.valueCellStyle}>
              {data?.service?.summarizer.completed ?? '...'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};
