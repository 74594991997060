import {
  Avatar,
  makeStyles,
  mergeClasses,
  Spinner,
} from '@fluentui/react-components';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Chat, ChatMessage, ChatMyMessage } from '@fluentui-contrib/react-chat';
import { ChatHistory, ChatHistoryMessage } from '@axys-notes/common';
import Markdown from 'react-markdown';
import gfm from 'remark-gfm';

export type LoadingMessage = {
  user: false;
  loading: true;
};

export type ChatPanelMessage = ChatHistoryMessage | LoadingMessage;

const useStyles = makeStyles({
  chatStyle: {
    display: 'flex',
  },
  autoScrollStyle: {
    flex: 1,
    overflow: 'auto',
  },
  spinnerStyle: {
    marginTop: '5px',
  },
});

export const ChatPanel = (props: {
  className?: string;
  discussionId: string;
  chatHistory?: ChatHistory;
  pendingHistory: ChatPanelMessage[];
}) => {
  const { className, chatHistory, discussionId, pendingHistory } = props;
  const styles = useStyles();

  const combined: ChatPanelMessage[] = useMemo(() => {
    return [...(chatHistory?.messages || []), ...pendingHistory];
  }, [chatHistory, pendingHistory]);

  const autoScrollEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    if (autoScrollEndRef.current) {
      autoScrollEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom, discussionId, chatHistory, pendingHistory]);

  return (
    <Chat className={mergeClasses(styles.chatStyle, className)}>
      <div className={styles.autoScrollStyle}>
        {!!combined.length &&
          combined.map((message, index) =>
            message.user ? (
              <ChatMyMessage key={index}>{message.message}</ChatMyMessage>
            ) : (
              <ChatMessage
                author="Alyx Notes"
                key={index}
                avatar={<AxysNotesAvatar />}
              >
                {(message as LoadingMessage).loading ? (
                  <Spinner size="tiny" className={styles.spinnerStyle} />
                ) : (
                  <Markdown
                    remarkPlugins={[gfm]}
                    children={(message as ChatHistoryMessage).message}
                  />
                )}
              </ChatMessage>
            )
          )}
        <div ref={autoScrollEndRef} />
      </div>
    </Chat>
  );
};

export const AxysNotesAvatar = () => (
  <Avatar
    image={{ src: require('../../../assets/icon-color.png') }}
    name="Alyx Notes"
    badge={{ status: 'available' }}
  />
);
