import { z } from 'zod';

export const ChatDescriptionSchema = z.object({
  discussionId: z.string(),
  name: z.string().optional(),
  timestamp: z.number(),
  recording: z.object({
    recordingId: z.string(),
    meetingName: z.string().optional()
  }).optional()
})

export type ChatDescription = z.infer<typeof ChatDescriptionSchema>
