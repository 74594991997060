import React from 'react';
import { useIsLargeScreen } from '../../utils/use-is-large-screen';
import { makeStyles } from '@fluentui/react-components';
import { Chats } from '../../components/chats';

const useStyles = makeStyles({
  containerStyle: {
    flex: 1,
    gap: '20px',
    padding: '20px',
    display: 'grid',
  },
  chatsStyle: { flex: 1 },
});

export const ChatsPage = () => {
  const isLargeScreen = useIsLargeScreen();

  const styles = useStyles();

  return (
    <div
      className={styles.containerStyle}
      style={{
        gridTemplateAreas: isLargeScreen
          ? `
          'table'
         `
          : `
          'table'
        `,
        gridTemplateColumns: isLargeScreen ? '1fr' : '1fr',
        gridTemplateRows: isLargeScreen ? '1fr' : '1fr',
      }}
    >
      <Chats className={styles.chatsStyle} />
    </div>
  );
};
