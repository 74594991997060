import { useTeamsContext } from '../providers/teams-context-provider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ChatCreate,
  ChatCreateResponse,
  ChatDescription,
  ChatHistory,
  ChatMessage,
  ChatResponseChunk,
} from '@axys-notes/common';
import { BACKEND_URL } from '../constants';
import { handleErrorResponse } from './common';
import { useCallback, useMemo, useRef, useState } from 'react';
import { createEventSource } from 'eventsource-client';
import type { EventSourceClient } from 'eventsource-client/src/types';

export const useCreateChatMutation = () => {
  const { authCode, renewAuthCode } = useTeamsContext();
  const queryClient = useQueryClient();
  return useMutation<ChatCreateResponse, Record<string, unknown>, ChatCreate>({
    mutationKey: ['chat-create'],
    mutationFn: async (query: ChatCreate) => {
      const response = await fetch(`${BACKEND_URL}/api/chat`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authCode ? `Bearer ${authCode}` : '',
        },
        body: JSON.stringify(query),
      });

      await handleErrorResponse(response, renewAuthCode);
      return response.json();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['chat-list'],
      });
    },
  });
};

export const useDeleteChatMutation = () => {
  const { authCode, renewAuthCode } = useTeamsContext();
  const queryClient = useQueryClient();
  return useMutation<void, Record<string, unknown>, string>({
    mutationKey: ['chat-create'],
    mutationFn: async (discussionId: string) => {
      const response = await fetch(`${BACKEND_URL}/api/chat/${discussionId}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authCode ? `Bearer ${authCode}` : '',
        },
      });
      await handleErrorResponse(response, renewAuthCode);
      return;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['chat-list'],
      });
    },
  });
};

export const useListChatsQuery = () => {
  const { authCode, renewAuthCode } = useTeamsContext();
  return useQuery<ChatDescription[]>({
    enabled: !!authCode,
    queryKey: ['chat-list'],
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchInterval: 10000,
    queryFn: async () => {
      const response = await fetch(`${BACKEND_URL}/api/chat`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authCode ? `Bearer ${authCode}` : '',
        },
      });
      await handleErrorResponse(response, renewAuthCode);
      return response.json();
    },
  });
};

export const useGetChatQuery = (discussionId?: string) => {
  const { authCode, renewAuthCode } = useTeamsContext();
  return useQuery<ChatHistory>({
    enabled: !!authCode,
    queryKey: ['chat', discussionId],
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    queryFn: async () => {
      const response = await fetch(`${BACKEND_URL}/api/chat/${discussionId}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authCode ? `Bearer ${authCode}` : '',
        },
      });
      await handleErrorResponse(response, renewAuthCode);
      return response.json();
    },
  });
};

export const useSendMessageMutation = () => {
  const { authCode } = useTeamsContext();
  const [isPending, setPending] = useState(false);
  const eventSourceRef = useRef<EventSourceClient | null>();

  const cancel = useCallback(() => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }
  }, []);

  const mutateAsync = useCallback(
    async (
      discussionId: string,
      message: ChatMessage,
      onChunk: (chunk: ChatResponseChunk) => void,
      onComplete: (error?: unknown) => void
    ) => {
      cancel();

      const eventSource = createEventSource({
        url: `${BACKEND_URL}/api/chat/${discussionId}`,
        method: 'POST',
        body: JSON.stringify(message),
        headers: {
          'Content-Type': 'application/json',
          Authorization: authCode ? `Bearer ${authCode}` : '',
        },
      });
      eventSourceRef.current = eventSource;
      setPending(true);
      for await (const { data } of eventSource) {
        if (!data) {
          break;
        }
        const json = JSON.parse(data);
        if (json.done) {
          onComplete(json.error);
          break;
        }
        onChunk(json);
      }
      eventSource.close();
      setPending(false);
    },
    [cancel, authCode]
  );

  return useMemo(
    () => ({
      mutateAsync,
      isPending,
      cancel,
    }),
    [cancel, mutateAsync, isPending]
  );
};
