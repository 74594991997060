import {
  Card,
  CompoundButton,
  Input,
  Label,
  makeStyles,
  mergeClasses,
  tokens,
  useId,
} from '@fluentui/react-components';
import React, { useCallback, useState } from 'react';
import { useRecordMeetingMutation } from '../../queries/recording';
import { BotAddRegular } from '@fluentui/react-icons';

const useStyles = makeStyles({
  cardStyle: {
    backgroundColor: tokens.colorSubtleBackgroundHover,
  },
  headerStyle: { paddingLeft: "10px" },
  formStyle: { display: 'flex', alignItems: 'center', flex: 1, gap: '20px' },
  labelStyle: { color: tokens.colorBrandForeground1 },
  inputStyle: { display: 'flex', flex: 1 },
  buttonStyle: { width: "200px" }
});

export const NewRecordingPanel = (props: {
  className?: string;
  disabled?: boolean;
}) => {
  const { className, disabled } = props;
  const { mutate, isPending: isSubmitPending } = useRecordMeetingMutation();
  const [inviteLink, setInviteLink] = useState<string>('');
  const submitInviteLink = useCallback(() => {
    mutate({
      meetingUrl: inviteLink,
    });
    setInviteLink('');
  }, [inviteLink]);

  const meetingLinkInput = useId('meeting-link-input');

  const styles = useStyles();

  return (
    <Card
      className={
        className ? mergeClasses(styles.cardStyle, className) : styles.cardStyle
      }
    >
      <h2 className={styles.headerStyle}>
        Invitez le Bot Alyx Notes à se joindre à votre réunion
      </h2>
      <div className={styles.formStyle}>
        <div style={{ flex: 1 }}>
          <Label
            htmlFor={meetingLinkInput}
            className={styles.labelStyle}
          >
            Adresse d'invitation à la réunion
          </Label>
          <Input
            className={styles.inputStyle}
            placeholder="https://teams.microsoft.com/l/meetup-join/..."
            value={inviteLink}
            disabled={disabled || isSubmitPending}
            onChange={(_, newValue) => {
              setInviteLink(newValue.value || '');
            }}
          />
        </div>
        <CompoundButton
          appearance="subtle"
          className={styles.buttonStyle}
          icon={<BotAddRegular />}
          disabled={disabled || isSubmitPending || !inviteLink}
          onClick={submitInviteLink}
          secondaryContent="Le bot rejoindra la réunion"
        >
          Rejoindre
        </CompoundButton>
      </div>
    </Card>
  );
};
