import { Route, Routes } from 'react-router-dom';
import {
  FluentProvider,
  teamsDarkTheme,
  teamsHighContrastTheme,
  teamsLightTheme,
} from '@fluentui/react-components';
import * as microsoftTeams from '@microsoft/teams-js';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { HomePage } from './pages/home';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TeamsContextProvider } from './providers/teams-context-provider';
import { ChatsPage } from './pages/chats';

const queryClient = new QueryClient();

export function App() {
  const [fluentUITheme, setFluentUITheme] = useState(teamsLightTheme);

  const updateTheme = useCallback((theme: string) => {
    switch (theme.toLocaleLowerCase()) {
      case 'default':
        setFluentUITheme(teamsLightTheme);
        break;
      case 'dark':
        setFluentUITheme(teamsDarkTheme);
        break;
      case 'contrast':
        setFluentUITheme(teamsHighContrastTheme);
        break;
    }
  }, []);

  useEffect(() => {
    /**
     * With the context properties in hand, your app has a solid understanding of what's happening around it in Teams.
     * https://docs.microsoft.com/en-us/javascript/api/@microsoft/teams-js/context?view=msteams-client-js-latest&preserve-view=true
     **/
    microsoftTeams.app.getContext().then((context) => {
      const theme = context.app.theme || 'default';
      updateTheme(theme);

      /**
       * Tells Microsoft Teams platform that we are done saving our settings. Microsoft Teams waits
       * for the app to call this API before it dismisses the dialog. If the wait times out, you will
       * see an error indicating that the configuration settings could not be saved.
       **/
      microsoftTeams.app.notifySuccess();
    });

    /**
     * Theme change handler
     * https://docs.microsoft.com/en-us/javascript/api/@microsoft/teams-js/microsoftteams?view=msteams-client-js-latest#registerOnThemeChangeHandler__theme__string_____void_
     **/
    microsoftTeams.app.registerOnThemeChangeHandler((theme) => {
      updateTheme(theme);
    });
  }, [updateTheme]);

  return (
    <TeamsContextProvider>
      <FluentProvider
        theme={fluentUITheme}
        style={{ backgroundColor: 'transparent', height: '100%', display: 'flex', flex: 1 }}
      >
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<div></div>}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/chats" element={<ChatsPage />} />
              {/*<Route*/}
              {/*  path="/recording/:recordingId"*/}
              {/*  element={<RecordingView />}*/}
              {/*/>*/}
            </Routes>
          </Suspense>
        </QueryClientProvider>
      </FluentProvider>
    </TeamsContextProvider>
  );
}

export default App;
