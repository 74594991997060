import { RecordingDTO } from '@axys-notes/common';
import {
  Avatar,
  Card,
  makeStyles,
  mergeClasses,
  Table,
  TableBody,
  TableCell,
  TableRow,
  tokens,
} from '@fluentui/react-components';
import { List, ListItem } from '@fluentui/react-components';
import { CrownRegular } from '@fluentui/react-icons';
import React, { forwardRef, useMemo } from 'react';
import { RecordingStatus } from '../recordings-list-panel';
import { noInfosLabel } from '../../utils/no-infos-label';
import { EditableValue } from '../editable-value';
import { useTeamsContext } from '../../providers/teams-context-provider';

type Props = {
  className?: string;
  data: RecordingDTO;
  onRename: (newName: string) => void;
};

const useStyles = makeStyles({
  cardStyle: {
    backgroundColor: tokens.colorSubtleBackgroundHover,
  },
  h2Style: { fontWeight: 'bold', paddingLeft: '15px' },
  headerCellStyle: { fontWeight: 500, width: '30%' },
  valueCellStyle: { width: '70%' },
  attendeesListItemStyle: {
    margin: '10px 0px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
});

export const GlobalInformations = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { className, data, onRename } = props;
    const { teamsContext } = useTeamsContext();
    const styles = useStyles();

    const editable = useMemo(() => {
      const userId = teamsContext?.user?.id;
      const isSubmitter = userId === data.submittedBy;
      const isOrganizer = !!data.roster.find(
        (participant) =>
          participant.id === userId && participant.organizer === true
      );
      console.log('isSubmitter', isSubmitter);
      console.log('isOrganizer', isOrganizer);
      return isSubmitter || isOrganizer;
    }, [data, teamsContext?.user?.id]);

    const minutes = useMemo(
      () =>
        data.startTime && data.endTime
          ? Math.ceil((data.endTime - data.startTime) / (60 * 1000))
          : undefined,
      [data.endTime, data.startTime]
    );

    return (
      <Card
        ref={ref}
        className={
          className
            ? mergeClasses(styles.cardStyle, className)
            : styles.cardStyle
        }
      >
        <h2 className={styles.h2Style}>Informations générales</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={styles.headerCellStyle}>Statut</TableCell>
              <TableCell className={styles.valueCellStyle}>
                <RecordingStatus status={data.status} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.headerCellStyle}>
                Objet de la réunion
              </TableCell>
              <TableCell className={styles.valueCellStyle}>
                <EditableValue
                  value={data.name}
                  editable={editable}
                  onSave={onRename}
                  onCancel={() => {
                    // do nothing
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.headerCellStyle}>
                Date de la réunion
              </TableCell>
              <TableCell className={styles.valueCellStyle}>
                {data.startTime
                  ? new Date(data.startTime).toLocaleString()
                  : noInfosLabel('...')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.headerCellStyle}>Durée</TableCell>
              <TableCell className={styles.valueCellStyle}>
                {minutes
                  ? `${minutes} minute${minutes > 1 ? 's' : ''}`
                  : noInfosLabel('...')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.headerCellStyle}>
                Participants
              </TableCell>
              <TableCell className={styles.valueCellStyle}>
                {Array.isArray(data.roster) && data.roster.length ? (
                  <List>
                    {data.roster.map((attendee, index) => (
                      <ListItem
                        key={index}
                        className={styles.attendeesListItemStyle}
                      >
                        <Avatar
                          aria-label={attendee.name}
                          name={attendee.name}
                          badge={
                            attendee.organizer
                              ? { icon: <CrownRegular /> }
                              : undefined
                          }
                        />
                        {attendee.name}
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  noInfosLabel('...')
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    );
  }
);
