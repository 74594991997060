import { z } from 'zod';
import { TranscriptSchema } from './transcript';

export const STTResultSchema = z.object({
  recordingId: z.string(),
  transcript: TranscriptSchema,
  meetingName: z.string().optional()
});

export type STTResult = z.infer<typeof STTResultSchema>;
