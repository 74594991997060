import React, { FormEvent, useCallback, useState } from 'react';
import { noInfosLabel } from '../../utils/no-infos-label';
import { Button, Field, Input, makeStyles } from '@fluentui/react-components';
import {
  DeleteRegular,
  EditRegular,
  SaveEditRegular,
} from '@fluentui/react-icons';

const useStyles = makeStyles({
  editableWrapperStyle: {
    display: 'flex',
    flexDirection: 'row',
  },
  textFieldStyle: {
    flex: 1,
  },
  editButtonStyle: {
    marginLeft: '10px',
  },
  saveButtonStyle: {
    flex: 0,
    marginLeft: '2px',
  },
  cancelButtonStyle: {
    flex: 0,
    marginLeft: '2px',
  },
});

export const EditableValue = (props: {
  value?: string;
  editable?: boolean;
  onSave: (newValue: string) => void;
  onCancel: () => void;
}) => {
  const { value, editable, onSave, onCancel } = props;
  const [editing, setEditing] = useState(false);
  const [newName, setNewName] = useState('');
  const styles = useStyles();

  const doEdit = useCallback(() => {
    setNewName(value || '');
    setEditing(true);
  }, [value]);

  const doSave = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setEditing(false);
      onSave(newName);
      setNewName('');
    },
    [newName, onSave]
  );

  const doCancel = useCallback(() => {
    setEditing(false);
    setNewName('');
    onCancel();
  }, [onCancel]);

  if (!editing) {
    return (
      <>
        {value || noInfosLabel('...')}
        {editable && (
          <Button
            className={styles.editButtonStyle}
            onClick={doEdit}
            icon={<EditRegular />}
          />
        )}
      </>
    );
  }

  return (
    <form className={styles.editableWrapperStyle} onSubmit={doSave}>
      <Input
        className={styles.textFieldStyle}
        value={newName}
        onChange={(_, data) => {
          setNewName(data.value);
        }}
      />
      <Button
        className={styles.saveButtonStyle}
        onClick={doSave}
        disabled={!newName}
        icon={<SaveEditRegular />}
      />
      <Button
        className={styles.cancelButtonStyle}
        onClick={doCancel}
        icon={<DeleteRegular />}
      />
    </form>
  );
};
